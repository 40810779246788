<template>
  <div>
    <el-container style="height: 100vh">
      <el-header style="background-color: #333a4d; padding: 0; height: 80px">
        <hander-com></hander-com>
      </el-header>
      <el-container>
        <el-aside
          width="220px"
          style="background-color: #333a4d; overflow: hidden"
        >
          <mune-com></mune-com>
        </el-aside>
        <el-main style="padding: 0">
          <transition name="fade">
            <router-view></router-view>
          </transition>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Logintoken: true,
    }
  },
  watch: {
    // $route(val) {
    //   // console.log(val);
    //   // console.log("layout");
    //   // if(this.val.name=='home'){
    //   //   this.$route.push('/index')
    //   // }
    // }
  }
}
</script>

<style lang="scss">
.router-link-active {
  text-decoration: none;
}
.el-asude {
  overflow: hidden;
}
</style>